import React from "react"
import { Box, Embed, Heading } from "theme-ui"

interface VideoCardProps {
  url: string
  header: string
}

const VideoCard = (props: VideoCardProps): JSX.Element => {
  const { url, header } = props

  return (
    <Box
      sx={{
        overflow: "hidden",
        maxWidth: "350px",
        boxShadow: 0,
        borderRadius: "1em",
      }}
    >
      <Embed src={url} />
      <Box bg="white" px={2} py={3}>
        <Heading sx={{ textAlign: "center", fontSize: [1,3] }} color="primary">
          {header}
        </Heading>
      </Box>
    </Box>
  )
}

export default VideoCard
