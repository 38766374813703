import React from "react"
import Layout from "../components/Layout"
import QuoteSection from "../components/QuoteSection"
import ContentSection from "../components/ContentSection"
import VideoCard from "../components/Home/VideoCard"
import { Box, Grid } from "theme-ui"
import SEO from "../components/SEO"

const IndexPage = () => {
  return (
    <Box>
      <SEO title="Videos" />
      <Layout>
        <QuoteSection />
        <div>
          <ContentSection
            header="Media Coverage"
            noHeaderBorder={true}
            body={
              <Grid columns={[1, 2, 3]} gap={5}>
                <VideoCard
                  url="https://www.youtube.com/embed/3DmaZYUqBfg"
                  header="An interesting conversation with Sri R. Natraj IPS - Part 1 of 2"
                />
                <VideoCard
                  url="https://www.youtube.com/embed/gtx0ufgMXzU"
                  header="An interesting conversation with Sri R. Natraj IPS - Part 2 of 2"
                />
                <VideoCard
                  url="https://www.youtube.com/embed/LpJIVLQQnK0"
                  header="National Youth Day - Sri R. Natraj IPS Speech - Part 1"
                />
                <VideoCard
                  url="https://www.youtube.com/embed/TocLakPBSjQ"
                  header="National Youth Day - Sri R. Natraj IPS Speech - Part 2"
                />
                <VideoCard
                  url="https://www.youtube.com/embed/iWdYwWaKgY4"
                  header="National Youth Day - Sri R. Natraj IPS Speech - Part 3"
                />
                <VideoCard
                  url="https://www.youtube.com/embed/wbeM7NdszpE"
                  header="Q & A session in ICAI talk by Sri R. Nataraj IPS - Part 1"
                />
                <VideoCard
                  url="https://www.youtube.com/embed/f45Ce7JzmJo"
                  header="Q & A session in ICAI talk by Sri R. Nataraj IPS - Part 1"
                />
              </Grid>
            }
          />
        </div>
      </Layout>
    </Box>
  )
}

export default IndexPage
